@media screen and (max-width: 400px) {
  #welcome {
    padding: 20px;
    width: 100%;
  }
  #registration {
    padding: 20px;
    width: 100%;
  }
  #info,
  #intro,
  #welcome,
  #contact,
  #keynote,
  #footer,
  #registration,
  #venue {
    width: 100%;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .text-justify-xs {
    text-align: center;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .text-justify-xs {
    text-align: justify;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .text-justify-xs {
    text-align: justify;
  }
}
